<template>
  <div class="py-2">
    <span v-html="value"/>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>
